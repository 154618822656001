import {combineReducers} from 'redux';
import {UserTypes} from 'mattermost-redux/action_types';
import {ActionTypes} from 'utils/constants.jsx';

const defaultState = {
    show: false,
}; 
function filters(state = {}, action) {
    const nextState = {...state};

    switch (action.type) {
        case 'VOICE_MAIL_FILTER': {
            return action.data;
        }
        default:
          return state;
    }
}
function setAddVoiceMailToTask (state = defaultState, action) {
    switch (action.type) {
    case ActionTypes.SHOW_ADD_VOICE_MAIL_TO_TASK_MODAL:
        return {
            ...action.data,
            show: true,
        };
    case ActionTypes.HIDE_ADD_VOICE_MAIL_TO_TASK_MODAL:
        return {
            show: false,
        };

    case UserTypes.LOGOUT_SUCCESS:
        return '';
    default:
        return state;
    }
}

function setAddVoiceMailToNote (state = defaultState, action) {
    switch (action.type) {
    case ActionTypes.SHOW_ADD_VOICE_MAIL_TO_NOTE_MODAL:
        return {
            ...action.data,
            show: true,
        };
    case ActionTypes.HIDE_ADD_VOICE_MAIL_TO_NOTE_MODAL:
        return {
            show: false,
        };

    case UserTypes.LOGOUT_SUCCESS:
        return '';
    default:
        return state;
    }
}


export default combineReducers({
    filters,setAddVoiceMailToTask,setAddVoiceMailToNote
});

import {combineReducers} from 'redux';
import {UserTypes} from 'mattermost-redux/action_types';
import {ActionTypes} from 'utils/constants.jsx';


function getSwitchToNoteLink(state = {}, action) {
    const nextState = {...state};
    switch (action.type) {
        case ActionTypes.STORED_NOTE_LINK: {
            return action.data;
        }
        default:
          return state;
    }
}


export default combineReducers({
    getSwitchToNoteLink
});
